import React from 'react'
import PropTypes from "prop-types"
import Img from 'gatsby-image'
import Layout from "../components/Layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SEO from "../components/seo"
import default_image from '../images/default_back.jpg'
class PageTemplate extends React.Component{
    render(){
        const page = this.props.data.wordpressPage
        let back_image = null ? default_image : page.featured_media.localFile.childImageSharp.resolutions.src;
        return(
            <Layout>
            <SEO title={page.title} keywords={[`telekomunikacije`, `inžinjering`]} />
            <div id={"_post_"+page.id} className="post">
                <section className="section page-title default" style={{ backgroundImage: `url(${back_image})` }}>
                    <div className="container">
                    <h1 dangerouslySetInnerHTML={{__html: page.title }} className="title"/>
                    </div>
                </section>

                <section className="page-news-header">
                    <div className="container">
                        <div className="is-right share">
                            <a href=""><FontAwesomeIcon icon={['fab', 'facebook-f']} aria-hidden="true" /></a>
                            <a href=""><FontAwesomeIcon icon={['fab', 'twitter']} aria-hidden="true" /></a>
                            <a href=""><FontAwesomeIcon icon={['fas', 'envelope']} aria-hidden="true" /></a>
                        </div>
                    </div>
                </section>

                <section className="section page-content">
                   <div className="container">
                  {/* <h1 dangerouslySetInnerHTML={{__html: page.title }} className="title"/> */}
                        {page.content && (<div dangerouslySetInnerHTML={{__html: page.content }} className="main" />)}
                        {!page.content && (<p className="under-construction">Stranica je trenutno u izradi.</p>)}
                   </div>
                </section>
            </div>
            </Layout>
        )
    }
}



export default PageTemplate

export const pageQuery = graphql`
    query currentPageQuery($id: String!) {
        wordpressPage(id: {eq: $id }){
        title
        content
        slug
        id
        date(formatString: "MMMM DD, YYYY")
        featured_media{
            localFile{
                childImageSharp{
                    resolutions(width:1920, height:500){
                        src
                        width
                        height
                    }
                }
            }
        }
    }
    site{
        id
        siteMetadata {
            title
            subtitle
        }
    }
}
`
